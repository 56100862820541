import { Options } from '../options';

export const options: Options = {
    transportMethods: [
        { id: 'transport', value: 'TRANSPORT' },
        { id: 'pickup', value: 'ODBIÓR OSO' },
    ],
    footerLinks: [
        { href: 'https://www.millesapori.pl/klauzula-informacyjna.html', translationKey: 'info-clause' },
        { href: 'https://www.millesapori.pl/polityka-prywatnosci.html', translationKey: 'privacy-policy' },
    ],
    logoSrc: {
        white: '',
        dark: '',
    },
    cart: {
        productsProposal: {
            linkToProduct: false,
            productNoField: false,
            useRarelyPurchasedItems: false,
        },
    },
    products: {
        lowAvailability: {
            availabilityValue: 5,
            markValueAsDanger: false,
            cartItemTooltip: false,
            addToCartAlert: false,
            quantityInputAlert: false,
        },
    },
    documents: {
        typeFilters: false,
    },
    roles: {
        ROLE_ADMIN: ['documents-financial', 'documents-liabilities', 'documents-sales'],
        ROLE_SALESPERSON: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'customer.table.icon',
            'customer.table.no',
            'customer.table.vat-no',
            'customer.table.company-name',
            'customer.table.restaurant-name',
            'customer.table.blocked',
            'customer.table.display-as',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
            'nav.categories',
            'merchant-limit',
            'merchant-limit.condition.shipment-balance',
        ],
        ROLE_USER: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
            'nav.categories',
            'merchant-limit',
            'merchant-limit.condition.shipment-balance',
        ],
        ROLE_USER_LIMITED: [
            'documents-financial',
            'documents-liabilities',
            'documents-sales',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
            'nav.dashboard',
            'nav.products',
            'nav.documents',
            'nav.categories',
            'merchant-limit',
            'merchant-limit.condition.shipment-balance',
        ],
    },
    standardFooter: true,
    footerAddresses: [],
};
